.form-group label input[type=radio] {
  vertical-align: middle;
}
.form-filter {
  .item-filter {
    .form-check-inline {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.video-review-filter {
  .item-filter {
    label {
      width: 90px;
      text-align: right;
    }
    &__control {
      width: calc(100% - 90px - 0.5rem);
    }
  }
  .video-type-filter {
    // width: calc(100% - 86px - 1rem);
    // label {
    //   width: 40px;
    // }
    // .item-filter {
    //   &__control {
    //     width: calc(100% - 40px - 0.5rem);
    //   }
    // }
  }
  .status-filter {
    .select {
      &__control {
        min-height: 31px;
        height: 31px;
      }
      &__value-container {
        padding: 0 4px!important;
      }
      &__multi-value {
        margin: 1px;
      }
      &__indicator {
        padding: 4px;
      }
    }
    &__list {
      label {
        width: auto;
      }
    }
  }
}
