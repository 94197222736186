.table>tbody>tr>td {
    vertical-align: top !important;
}

.mainTitle {
    font-size: large;
    font-weight: 600;
}

.titleLine4 {
    font-style: italic;
}

.u-truncate-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5 !important;
    white-space: pre-wrap;
}

.totalLine {
    margin-left: auto !important;
    font-weight: 600;
}

.withPrice {
    color: darkorange;
    font-weight: bolder;
    font-size: large;
}

.withNoPrice {
    color: darkblue;
    font-weight: bolder;
    font-size: large;
}

.unclickableOrders {
    cursor: default;
}

.clickableOrders {
    cursor: pointer;
}

.pendingOrders {
    color: lightblue;
    font-weight: bold;
    margin-left: 8px;
}

.pendingRec {
    width: 16px;
    height: 16px;
    border: 1px solid lightblue;
    background-color: lightblue;
}

.completedOrders {
    color: darkgreen;
    font-weight: bolder;    
    margin-left: 8px;
}

.completedRec {
    width: 16px;
    height: 16px;
    border: 1px solid darkgreen;
    background-color: darkgreen;
}

.canceledOrders {
    color: darkorange;
    font-weight: bolder;
    margin-left: 8px;
}

.canceledRec {
    width: 16px;
    height: 16px;
    border: 1px solid darkorange;
    background-color: darkorange;
}