.data-list {
  .data-list {
    .custom-scrollbar {
      height: auto;
      max-height: calc(100vh - 65px - 2.5rem - 33px - 0.5rem - 146px - 1.25rem - 1rem - 25px);
      // max-height: 71vh;
      overflow-y: auto;
      scroll-behavior: smooth;
    }
  }
  &.search-form-hidden {
    .data-list {
      .custom-scrollbar {
        height: auto;
        max-height: calc(100vh - 65px - 2.5rem - 33px - 0.5rem - 68px - 1.25rem - 1rem - 25px);
        // max-height: 71vh;
        overflow-y: auto;
        scroll-behavior: smooth;
      }
    }
  }
  &--auditlog {
    .data-list {
      .custom-scrollbar {
        max-height: calc(100vh - 65px - 2.5rem - 33px - 0.5rem - 111px - 1.25rem - 1rem - 25px);
      }
    }
    .json-viewer {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      height: 160px;
    }
  }
}
.reviewer-item {
  .reviewer-name {
    position: relative;
    span {
      // display: none;
      // position: absolute;
      top: -1px;
      right: 0;
      margin-left: 5px;
    }
    &:hover {
      span {
        // display: block;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .video-review-list {
    .data-list {
      .custom-scrollbar {
        max-height: calc(100vh - 65px - 2.5rem - 33px - 0.5rem - 189px - 1.25rem - 1rem - 25px);
      }
    }
  }
  
}

@media screen and (max-width: 991px) {
  .video-review-list {
    .data-list {
      .custom-scrollbar {
        max-height: calc(100vh - 65px - 2.5rem - 33px - 0.5rem - 215px - 1.25rem - 1rem - 25px);
      }
    }
  }
  
}