.react-datepicker-wrapper {
  .react-datepicker__input-container {
    position: relative;
    font-family: 'fuvi-icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:after {
      position: absolute;
      top: 5px;
      right: 7px;
      content: "\e93b";
      font-size: 1.2rem;
      z-index: 1;
      display: block;
      color: #b3b4b5;
    }

    input {
      display: block;
      width: 125px;
      height: calc(1.8125rem + 2px);
      padding: 0.25rem 0.7rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.2rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      padding-right: 25px;
      z-index: 0;
      &:focus {
        outline: none;
      }
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: left;
    }
  }
  &:nth-child(1) {
    // margin-right: 0.5rem;
  }
}
.react-datepicker__header {
  button, select {
    border: 1px solid #ced4da;
    outline: none;
  }
  select {
    option {
      padding: 3px 5px;
    }
  }
}
.drp-ranges {
  width: 155px;
  // padding-left: 30px;
  position: relative;
  &:before {
    font-family: 'fuvi-icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 6px;
    left: 6px;
    content: "\e912";
    font-size: 1.2rem;
    z-index: 1;
    display: block;
    color: #b3b4b5;
  }
}