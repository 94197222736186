.data-filter {
  .item-filter {
    label {
      width: 45px;
    }
    &__control {
      width: calc(100% - 45px - 0.5rem);
    }
  }
  .reviewer-filter {
  }
  .status-filter {
    .select {
      &__control {
        min-height: 31px;
        height: 31px;
      }
      &__value-container {
        padding: 0 4px!important;
      }
      &__multi-value {
        margin: 1px;
      }
      &__indicator {
        padding: 4px;
      }
    }
    &__list {
      label {
        width: auto;
      }
    }
  }
}
